<!--
Material design styled dropdown-combo component. Behavior is similar
to a dropdow except:

1. Text can be typed into the input to filter the results of the
   dropdown list.
2. We have a lot more control over the look and feel than with
   native inputes.

Example: Usage is very simlar to <select> inputs. The chief difference
         is that the value is set on the select tag instead of setting
         the currently selected item in the <options> list.

<material-dropdown id="user_job_role" name="user[job_role]" member-value="head-honcho">
  <option value="senior-executive">Senior Executive</option>
  <option value="head-honcho">Head Honcho</option>
  <option value="powerless-middleman">Powerless Middleman</option>
  <option value="pawn">Pawn</option>
</material-dropdown>
-->
<template>
  <span :class="`material-dropdown ${cssClass}`">
    <div ref    = "inputAndIcon"
         :class = "inputAndIconClasses"
         @click = "toggleDropdown">

      <!-- display input -->
      <input ref                = "input"
             v-if               = "internalSearchable"
             v-model.trim:value = "internalText"
             type               = "text"
             class              = "input"
             :placeholder       = "placeholder"
             @blur              = "onBlur"
             @focus             = "onFocus"
             @keyup             = "keyup"
             @keydown.enter     = "select(selected)"
             @keydown.tab       = "select(selected)">
      </input>
      <div v-else class="pl-1em">
        {{internalText || placeholder}}
      </div>

      <!-- this is where the real post value is stored. -->
      <input type    = "hidden"
             :id     = "id"
             :name   = "name"
             v-model = "internalValue"
      ></input>

      <!-- clickable chevron icon -->
      <span v-show = "internalShowDecorations">
        <i :class="internalChevronClasses"></i>
      </span>
    </div>

    <div v-show="internalShowDecorations" class="material-dropdown-underline"></div>

    <!-- the dropdown list of options -->
    <ul ref="dropdown"
        :class="internalDropdownClass">
      <li v-for  = "item in filteredItems"
          :class = "dropdownItemClasses(item)"
          @click = "select(item)">
        <div class="pl-1em pr-1em flex justify-left">
          <div v-if="internalShowImage" >
            <img v-if="imagePresent(item)" :src="item.image_url" class="w-40 height-auto border-radius-3" />
            <div v-else class="w-40"></div>
          </div>
          <div class="pl-10" v-html="item.text"></div>
        </div>
      </li>
    </ul>

    <!-- This collects data from the <option> elements within the slot -->
    <div ref="data" class="material-dropdown-slot d-none">
      <slot>
      </slot>
    </div>
  </span>
</template>

<script>
  import each             from 'lodash/each'
  import find             from 'lodash/find'
  import map              from 'lodash/map'
  import {blank,
          present,
          toBoolean}      from '../lib/utils.js'

  export default {
    props: {
      // Automatically select the first item in the list
      autoSelect:             { default: true },
      // Set an alternative chevron color
      chevronColor:           { default: 'medium-blue' },
      // Show animated blue underline
      blueUnderline:          { default: true },
      // Extra CSS class(es) to add to the element.
      cssClass:               { default: '' },
      // Classes applied to the dropdown element.
      dropdownClass:          { default: '' },
      // Classes applied to each item in the dropdown.
      externalItemClasses:    { default: '' },
      // Use this list for dropdown items if present. Expected
      // array format is [{ value: 'value1', text: 'text1'},
      //                  { value: 'value2', text: 'text2'}]
      externalItems:          { default: () => [] },
      // Passed along to the internal input attribute for posting.
      id:                     { default: '' },
      // Classes applied to the input element.
      inputClass:             { default: '' },
      // Passed along to the internal input attribute for posting.
      name:                   { default: '' },
      // Text to display prior to having a value
      placeholder:            { default: null },
      // Text can be entered to filter the dropdown if true.
      searchable:             { default: false },
      // If true, show the cheveron or underline.
      showDecorations:        { default: true},
      // If true, show the image_url to the right of the text.
      showImage:              { default: false },
      // If true, show placeholder when user enters input.
      showPlaceholderOnFocus: { default: true },
      // Display a smaller chevron
      smallChevron:           { default: false },
      // If true, show the underline.
      showUnderline:          { default: true },
      // Default value selected.
      modelValue:             { default: null }
    },

    data() {
      return {
        // Internal: Array of child objects in the dropdown
        children:            [],
        // Internal: Whether the dropdown is visible.
        dropdownVisible:     false,
        // Internal: show only filtered items if true.
        filtering:           false,
        // Internal: Mutable boolean version of autoSelect prop.
        internalAutoSelect:  toBoolean(this.autoSelect),
        // Internal: hold onto original placeholder value.
        internalPlaceholder: this.placeholder,
        // Internal: Text displayed in the visible. input.
        internalText:        null,
        // Internal: Converted showDecorations prop to a boolean.
        internalShowDecorations: toBoolean(this.showDecorations),
        // Internal: Convert showUnderline prop to a boolean.
        internalShowUnderline:   toBoolean(this.showUnderline),
        // Internal: Keep track of visibility and orienation changes
        intersectionObserver:  new IntersectionObserver(this.onIntersectionChange, {root: this.$el}),
        // Internal: The currently selected item.
        selected:            null
      }
    },

    computed: {
      // Internal: Calculated classes applied to the dropdown element.
      internalDropdownClass() {
        let display = this.dropdownVisible ? 'material-dropdown-display-block' : 'material-dropdown-display-none'

        return `z-index-top material-dropdown-panel position-absolute pointer ${this.dropdownClass} ${display} overflow-y-auto`
      },

      // Internal: convert the searchable prop to a boolean.
      internalSearchable() {
        return toBoolean(this.searchable)
      },

      // Internal Calculated classes applied to the input and icon div
      inputAndIconClasses() {
        let classes = `${toBoolean(this.blueUnderline) ? 'input-and-icon' : ''} ${this.inputClass}`

        if (this.internalShowDecorations && this.internalShowUnderline) {
          classes = `material-dropdown-border ${classes}`
        }

        return classes
      },

      internalShowImage() {
        return toBoolean(this.showImage)
      },

      // Internal classes used with the chevron. Used in case of alternative chevron colors
      internalChevronClasses() {
        return `bi bi-chevron-down pointer ${this.chevronColor} ${toBoolean(this.smallChevron) ? 'f-0-7em' : ''}`
      },

      // Internal: Currently selected value.
      internalValue() {
        return this?.selected?.value
      },

      // Internal: items filtered on the text in the input.
      filteredItems() {
        let items = present(this.externalItems) ? this.externalItems : this.items

        if (!this.filtering || present(this.externalItems)) return items

        let query = this.internalText?.trim().replace(' ', '.')
        return items.filter((item) => item.text.match(new RegExp(query, 'i')))
      },

      // Internal: Child data mapped into a data structure of items.
      items() {
        return map(this.children, (i) => {
          return {value: i.getAttribute('value'), text: i.innerText}
        })
      }
    },

    methods: {
      onBlur() {
        // Give the other elements just the tiniest bit of time to
        // update before restoring this value.
        if (!toBoolean(this.showPlaceholderOnFocus)) {
          setTimeout(() => {
            this.$refs.input.placeholder = this.placeholder
          }, 100)
        }

        this.$emit('blur', this.internalText)
      },

      // Internal: If observable material dropdowns change, update the
      // width. This is done because the inputs have no discernable
      // width, when hidden in an invisible modal popup. This allows
      // us to capture when that visibility changes and adjust the
      // width accordingly.
      onIntersectionChange(entries, observer) {
        if (entries[0].isIntersecting) {
          this.updateWidth()
        }
      },

      // Internal: Close the dropdown if the click happens outside the component
      documentClick(e) {
        if (( this.$el !== e.target) && !this.$el.contains(e.target)) {
          this.dropdownVisible = false
        }
      },

      // Internal: Classes applied to the dropdown items.
      dropdownItemClasses(item) {
        let selectedClass = item?.value == this.selected?.value ? 'selected' : ''

        return `material-dropdown-item pv-10 ${selectedClass} ${this.externalItemClasses}`
      },

      imagePresent(item) {
        return present(item.image_url)
      },

      // Internal: Input focus event.
      onFocus() {
        if (!toBoolean(this.showPlaceholderOnFocus)) {
          this.$refs.input.placeholder = ''
        }
      },

      // Internal: Input keyup event.
      keyup(event) {
        this.dropdownVisible = true
        this.filtering       = true

        if (this.internalAutoSelect) {
          this.selected = this.filteredItems[0]
        }

        this.$emit('keyup', event)
      },

      // Internal: Select the given item.
      select(item) {
        this.filtering       = false
        this.dropdownVisible = false

        if (blank(item)) return

        this.selected     = item
        this.internalText = item.text

        this.$nextTick(() => {
          this.$emit('change', item )
          this.$emit('click', item )
          this.$emit('input', item.value)
          this.$emit('update:modelValue', item.value)
        })
      },

      // Internal: Toggle the dropdown visibility.
      toggleDropdown() {
        this.dropdownVisible = !this.dropdownVisible

        if (this.dropdownVisible && present(this.$refs.input)) {
          this.$refs.input.focus()
        }
      },

      updateWidth() {
        // The dropdown should be as wide as the input
        if (this.$refs.inputAndIcon.clientWidth != 0) {
          this.$refs.dropdown.style.setProperty('min-width', `${this.$refs.inputAndIcon.clientWidth}px`, 'important')
        }
      }
    },

    mounted() {
      this.children     = this.$refs.data.children
      this.selected     = this.items.find((item) => {return item.value == this.modelValue})

      // If there are no items to select, it's ok to display the given value in the intput.
      if (blank(this.selected)) {
        this.internalText = this.modelValue
      }
      else {
        this.internalText = this.selected?.text
      }

      // Watch for visibility changes so the dropdown width can be
      // adjusted.
      this.intersectionObserver.observe(this.$el)

      this.updateWidth()
      document.addEventListener('click', this.documentClick)
    }
  }
</script>

<style lang="scss">
  @import "../../assets/stylesheets/colors.scss";

  .material-dropdown {
    option {
      display: none !important;
    }

    // Force scrollbar because MacOS auto-hides it
    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: #8e8e93 !important;
      box-shadow: 0 0 1px rgba(255,255,255,.5);
    }

    .material-dropdown-border {
      display: flex;
      justify-content: space-between;
      border-bottom: solid 1px $light-gray;
      cursor: pointer;
    }

    .material-dropdown-panel {
      border: transparent;
      border-radius: 0 0 5px 5px;
      list-style: none;
      padding: 0;
      overflow: hidden;
      z-index: 999999 !important;
      background-color: white;
      width: fit-content !important;
    }

    .material-dropdown-item {
      display: flex;
      justify-content: flex-start;
      padding: 0.5em 0.8em;
      white-space: nowrap;
    }

    .material-dropdown-item:hover {
      background-color: $dashboard-lightest-gray;
    }

    input {
      max-width: 100%;
      border: none;
      padding-left: 1em;
      flex-grow: 1;
    }

    input:focus {
      outline: none;
      box-shadow: none;
    }

    // Animate the dropdown
    .material-dropdown-display-none {
      max-height: 0;
      box-shadow: none;
      height: 0;
    }

    // Animate the dropdown
    .material-dropdown-display-block {
      height: auto;
      max-height: 350px; // arbitrarily large value just to satisfy height-auto transition
      box-shadow: 0 1px 1px 0 rgba(65, 69, 73, 0.3 ), 0 1px 3px 1px rgba(65, 69, 73, 0.15);
    }

    .material-dropdown-underline {
      border-top: solid 2px $dashboard-medium-blue;
      width: 0;
      max-width: 100%;

      @media(max-width: 1000px) {
        max-width: 250px
      }
    }

    // Animate the blue underline
    // TODO: Design is handled by bootstrap, not material.
    /* .input-and-icon:focus-within + .material-dropdown-underline {
       width: 300px;
       transition: width 0.2s;
       } */
  }
</style>
