<!--
Public: Renders an intentionally simple modal and provides a slot for components.

<modal ref='modal' modal-container-classes = "w-800">
  <div>
    <div>You can put in basic HTML</div>
    <input-entity-autocomplete></input-entity-autocomplete>
    <div>Or even another component</div>
  </div>
</modal>
-->
<template>
  <transition name="modal">
    <div :class       = "maskClasses"
         v-if         = "showModal"
         @keydown.esc = "showModal = false">
      <div ref="modalWrapper" :class = "wrapperClasses">
        <div :class   = "containerClasses"
             :style   = "internalMaxWidth"
             :id      = "modalContainerId"
             @click   = "onPreventClick"
             tabindex = "-1">
          <slot></slot>
        </div><!-- containerClasses -->
      </div><!-- wrapperClasses -->
    </div>
  </transition>
</template>

<script>
  import { isMobile,
           toBoolean }  from '../lib/utils.js'

  export default {
    components: {},

    props: {
      // Whether to close the modal when clicked outside.
      closeOnClick:          { default: true },
      // Max width for the modal-container.
      maxWidth:              { default: '' },
      // classes for the modal mask.
      modalMaskClasses:      { default: '' },
      // classes for the modal wrapper.
      modalWrapperClasses:   { default: '' },
      // classes for the modal container.
      modalContainerClasses: { default: '' },
      // id for the modal container.
      modalContainerId:      { default: '' },
      // Prevent click event from bubbling if true.
      preventClick:          { default: true }
    },

    data() {
      return {
        // Public: Whether to display the modal.
        showModal: false
      }
    },

    computed: {
      // Internal: Classes for outermost div.
      maskClasses() {
        const fixed = isMobile() ? '' : 'fixed'

        return `modal-mask ${fixed} ${this.modalMaskClasses}`
      },

      // Internal: Wrapper classes for modal.
      wrapperClasses() {
        return `modal-wrapper ${this.modalWrapperClasses}`
      },

      // Internal: Classes for innermost div. Applied to modal.
      containerClasses() {
        const center = isMobile() ? 'position-relative top-15p' : 'vertical-center'

        return `ui-scrollbar modal-container ${this.modalContainerClasses} ${center}`
      },

      // Internal: Set a max-width for the modal-container.
      internalMaxWidth() {
        return `max-width: ${this.maxWidth}px;`
      },

      // Internal: Import function from utils to determin if mobile device.
      isMobile: isMobile
    },

    methods: {
      // Public: Hide the modal.
      hide() {
        this.showModal = false
      },
      // Public: Show the modal.
      show() {
        this.showModal = true
      },

      // Internal: Selectively prevent the default click event.
      onPreventClick(event) {
        if (toBoolean(this.preventClick)) {
          event.preventDefault()
        }
      }
    },

    mounted() {
      let _this = this
      window.addEventListener('click', (e) => {
        // Vue ref costruct isn't sufficient to address this workflow,
        // so we're using a native html document.querySelector call.
        const wrapper = document.querySelector(".modal-wrapper")

        if (e.target == wrapper && this.closeOnClick){
          e.preventDefault()
          this.showModal = false
        }
      })
    }
  }
</script>

<style scoped>
  @import "../../assets/stylesheets/colors.scss";

  .modal-mask {
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
  }

  .modal-wrapper {
    z-index: 9997;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    background: rgba(50,50,50, 0.5);
  }

  .modal-container {
    z-index: 9999;
    overflow-y: auto;
    margin: 1% auto;
    /* margin-top: 3em; */
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    border-radius: 10px;
  }

  .modal-enter-active, .modal-leave-active {
    transition: opacity .5s;
  }

  .modal-enter, .modal-leave-to {
    opacity: 0;
  }

  .scrollable-popup {
    max-height: calc(93% - 70px);
    cursor: auto;
  }

  .vertical-center {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .analytics-popup-max-height {
    max-height: calc(93% - 70px);
  }
</style>
